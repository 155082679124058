import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Alert from 'react-s-alert'
import ReCAPTCHA from 'react-google-recaptcha'
import { COLOR_CONSTANTS, space, radius } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import { pxToRem } from 'helpers'
import { Flex, Box } from 'components/Layout'
import { Text } from 'components/Typography'
import Input from 'components/Input'
import Dropdown from 'components/DropDown'
import ButtonWithLoading from 'components/ButtonWithLoading'
import RunningText from 'components/RunningText'
import LocalImage from 'components/LocalImage'

import imageCopy from 'static/images/social-media-tools/copy.svg'

const StyledFormComponentWrapper = styled(Flex)`
  text-align: left;
  flex-direction: column;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
`

const StyledInput = styled(Input)`
  height: 50px;
`

const StyledCaptionWrapper = styled(Flex)`
  border-radius: ${radius.l};
  background: ${COLOR_CONSTANTS.WHITE};
  padding: ${pxToRem(24)};
`

const StyledRunningTextWrapper = styled(Flex)`
  flex-direction: column;
  border-radius: ${radius.l};
  border: 1px solid rgba(109, 124, 143, 0.3);
  background: rgba(109, 124, 143, 0.05);
  padding: ${space.m};
`

const StyledCopyWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(12)};
  background: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid #b6cef0;
  cursor: pointer;
  border-radius: ${radius.xl};
`

const StyledButtonWithLoading = styled(ButtonWithLoading)`
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const { DESCRIPTION, KEYWORDS, LANGUAGE, STYLE, NETWORK, CATEGORY } = {
  DESCRIPTION: 'description',
  KEYWORDS: 'keywords',
  LANGUAGE: 'language',
  STYLE: 'style',
  NETWORK: 'network',
  CATEGORY: 'category',
}

const networks = [
  { value: 'facebook', label: 'Facebook' },
  { value: 'google business', label: 'Google Business' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'linkedin', label: 'LinkedIn' },
  { value: 'pinterest', label: 'Pinterest' },
  { value: 'reddit', label: 'Reddit' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'youtube', label: 'YouTube' },
]

const styles = [
  { value: 'casual', label: 'Casual 🤗' },
  { value: 'professional ', label: 'Professional 🤓' },
  { value: 'conversational', label: 'Conversational 💬' },
  { value: 'friendly', label: 'Friendly 😃' },
  { value: 'humorous', label: 'Humorous 😆' },
  { value: 'authoritative', label: 'Authoritative 💪' },
  { value: 'sarcastic', label: 'Sarcastic 🙃' },
  { value: 'emotional', label: 'Emotional 😭' },
  { value: 'storytelling', label: 'Storytelling 📖' },
  { value: 'creative', label: 'Creative 🎨' },
  { value: 'engaging', label: 'Engaging 🤗' },
  { value: 'inspirational', label: 'Inspirational 💪' },
]

const languages = [
  { value: 'arabic', label: 'Arabic' },
  { value: 'bengali', label: 'Bengali' },
  { value: 'english', label: 'English' },
  { value: 'french', label: 'French' },
  { value: 'german', label: 'German' },
  { value: 'greek', label: 'Greek' },
  { value: 'hindi', label: 'Hindi' },
  { value: 'hebrew', label: 'Hebrew' },
  { value: 'japanese', label: 'Japanese' },
  { value: 'italian', label: 'Italian' },
  { value: 'mandarin', label: 'Mandarin' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'polish', label: 'Polish' },
  { value: 'portuguese', label: 'Portuguese' },
  { value: 'tamil', label: 'Tamil' },
  { value: 'turkish', label: 'Turkish' },
  { value: 'ukranian', label: 'Ukranian' },
  { value: 'urdu', label: 'Urdu' },
]

const categories = [
  { value: 'education', label: 'Education' },
  { value: 'non profit', label: 'Nonprofit' },
  { value: 'retail', label: 'Retail' },
  { value: 'interior design', label: 'Interior design' },
  { value: 'real estate', label: 'Real estate' },
  { value: 'architecture', label: 'Architecture' },
  { value: 'wellness', label: 'Wellness' },
  { value: 'tech', label: 'Tech' },
  { value: 'health', label: 'Health' },
  { value: 'coaching', label: 'Coaching' },
  { value: 'hotel', label: 'Hotel' },
  { value: 'insurance', label: 'Insurance' },
  { value: 'clothing', label: 'Clothing' },
  { value: 'preschool', label: 'Preschool' },
  { value: 'jewelry', label: 'Jewelry' },
  { value: 'hair salon', label: 'Hair salon' },
  { value: 'nail salon', label: 'Nail salon' },
  { value: 'grocery', label: 'Grocery' },
  { value: 'furniture', label: 'Furniture' },
  { value: 'home goods', label: 'Home goods' },
  { value: 'ceramics', label: 'Ceramics' },
  { value: 'pet grooming', label: 'Pet grooming' },
  { value: 'clinic', label: 'Clinic' },
  { value: 'bookstore', label: 'Bookstore' },
  { value: 'lighting', label: 'Lighting' },
  { value: 'fabric store', label: 'Fabric store' },
  { value: 'beauty', label: 'Beauty' },
  { value: 'fitness', label: 'Fitness' },
  { value: 'foodie', label: 'Foodie' },
  { value: 'lifestyle', label: 'Lifestyle' },
  { value: 'gaming', label: 'Gaming' },
  { value: 'daily vlog', label: 'Daily vlog' },
  { value: 'family vlog', label: 'Family vlog' },
  { value: 'travel vlog', label: 'Travel vlog' },
  { value: 'comedy', label: 'Comedy' },
  { value: 'pet', label: 'Pet' },
  { value: 'artist', label: 'Artist' },
  { value: 'musician', label: 'Musician' },
  { value: 'reviews', label: 'Reviews' },
  { value: 'finance', label: 'Finance' },
  { value: 'chef', label: 'Chef' },
  { value: 'fashion', label: 'Fashion' },
].sort((a, b) => (a.value > b.value ? 1 : -1))

const FormComponent = ({ network, type }) => {
  let descriptionLabel = 'Topic'
  let descriptionPlaceholder = 'Topic'
  let buttonLabel = 'Generate caption'
  let buttonLabelInProgress = 'Generating caption'

  if (type === 'other-emoji-to-text') {
    descriptionLabel = 'Emoji'
    descriptionPlaceholder = 'Emoji to translated'
    buttonLabel = 'Translate'
    buttonLabelInProgress = 'Translating'
  } else if (type === 'other-text-to-emoji') {
    descriptionLabel = 'Text'
    descriptionPlaceholder = 'Text to convert to emoji'
    buttonLabel = 'Translate'
    buttonLabelInProgress = 'Translating'
  } else if (type === 'username') {
    buttonLabel = 'Generate username'
    buttonLabelInProgress = 'Generating'
  } else if (type === 'hashtag') {
    buttonLabel = 'Generate hashtags'
    buttonLabelInProgress = 'Generating'
  }

  const recaptchaRef = useRef()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [caption, setCaption] = useState(null)

  const onRecaptchaExpired = () => {
    console.log('recaptcha expired')
    recaptchaRef.current.execute()
  }

  const isDropdownValueRequiredShape = {
    value: Yup.string().required(),
    label: Yup.string().required(),
  }

  const ValidationSchema = () => {
    const data = {
      [DESCRIPTION]: Yup.string().required(`${descriptionLabel} is required`),

      // [STYLE]: Yup.object()
      //   .shape(isDropdownValueRequiredShape)
      //   .typeError('Style is required'),
    }

    if (!network && (type === 'caption' || type === 'hashtag' || type === 'username')) {
      data[NETWORK] = Yup.object()
        .shape(isDropdownValueRequiredShape)
        .typeError('Network is required')
    }

    return Yup.object().shape(data)
  }

  const handleSubmitForm = async (values) => {
    try {
      let recaptcha

      if (recaptchaRef && recaptchaRef.current) {
        recaptcha = recaptchaRef.current.getValue()

        if (!recaptcha) {
          await recaptchaRef.current.executeAsync()

          recaptcha = recaptchaRef.current.getValue()

          recaptchaRef.current.reset()
        }
      } else {
        recaptcha = 'disabled'
      }

      if (!recaptcha || recaptcha === 'disabled') {
        Alert.error(`Error verifying reCAPTCHA, please try again or contact support.`, { timeout: 5000 })
      } else {
        setCaption(null)
        setIsSubmitting(true)

        const body = {
          [DESCRIPTION]: values[DESCRIPTION] ? values[DESCRIPTION].trim() : undefined,
          [KEYWORDS]: values[KEYWORDS] ? values[KEYWORDS].trim() : undefined,
          [LANGUAGE]: values[LANGUAGE] ? values[LANGUAGE].value : undefined,
          [STYLE]: values[STYLE] ? values[STYLE].value : undefined,
          [NETWORK]: values[NETWORK] ? values[NETWORK].value : undefined,
          [CATEGORY]: values[CATEGORY] ? values[CATEGORY].value : undefined,
          recaptcha,
          type,
        }

        const res = await fetch(`${process.env.GATSBY_API_URL}/ai/generator`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        })

        const response = await res.json()

        const { caption, error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          setCaption({
            ...{
              id: new Date().getTime() + Math.floor(Math.random() * 1000),
              text: caption,
              isNew: true,
            },
          })
        }
      }
    } catch (error) {
      console.error(error)
      Alert.error(ERROR_MESSAGE, { timeout: 5000 })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleClickCopyCaption = async () => {
    try {
      await navigator.clipboard.writeText(caption.text)
      Alert.success(`Text has been copied`)
    } catch (err) {
      Alert.success(ERROR_MESSAGE)
    }
  }

  return (
    <StyledFormComponentWrapper>
      {type === 'caption' && (
        <Text fontSize="m" mb="l">
          We designed our social media caption generator to make copywriting process fun while saving your time and
          energy. All you have to do is add a bit of information and the tool will generate a caption options that you
          can use in your posts!
        </Text>
      )}

      {type === 'hashtag' && (
        <Text fontSize="m" mb="l">
          We designed our social media hashtag generator to make copywriting process fun while saving your time and
          energy. All you have to do is add a bit of information and the tool will generate hashtag options that you can
          use in your posts!
        </Text>
      )}

      <Formik
        initialValues={{
          [LANGUAGE]: null,
          [STYLE]: 'casual',
          [NETWORK]: network ? networks.find(({ value }) => value === network) : null,
          [DESCRIPTION]: '',
          [KEYWORDS]: '',
          [CATEGORY]: null,
        }}
        onSubmit={handleSubmitForm}
        validationSchema={ValidationSchema}
      >
        {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
          <Box>
            {!network && (type === 'caption' || type === 'hashtag' || type === 'username') && (
              <Box mb="m">
                <Dropdown
                  placeholder="Select network"
                  options={networks}
                  label="Network"
                  value={values[NETWORK]}
                  id={[NETWORK]}
                  onChange={(option) => {
                    setFieldValue(NETWORK, option)
                  }}
                  error={errors[NETWORK] && touched[NETWORK] && errors[NETWORK]}
                  openMenuOnFocus
                />
              </Box>
            )}
            {type === 'caption' && (
              <Box>
                <Dropdown
                  placeholder="Select tone of voice"
                  options={styles}
                  label="Tone of voice"
                  value={values[STYLE]}
                  id={[STYLE]}
                  onChange={(option) => {
                    setFieldValue(STYLE, option)
                  }}
                  error={errors[STYLE] && touched[STYLE] && errors[STYLE]}
                  openMenuOnFocus
                />
              </Box>
            )}

            {(type === 'caption' || type === 'hashtag' || type.includes('emoji')) && (
              <Box mt="m">
                <Dropdown
                  placeholder="Select language"
                  options={languages}
                  label="Language"
                  value={values[LANGUAGE]}
                  id={[LANGUAGE]}
                  onChange={(option) => {
                    setFieldValue(LANGUAGE, option)
                  }}
                  error={errors[LANGUAGE] && touched[LANGUAGE] && errors[LANGUAGE]}
                  openMenuOnFocus
                />
              </Box>
            )}

            {type === 'username' && (
              <Box mt="m">
                <Dropdown
                  placeholder="Select category"
                  options={categories}
                  label="Category"
                  value={values[CATEGORY]}
                  id={[CATEGORY]}
                  onChange={(option) => {
                    setFieldValue(CATEGORY, option)
                  }}
                  error={errors[CATEGORY] && touched[CATEGORY] && errors[CATEGORY]}
                  openMenuOnFocus
                />
              </Box>
            )}

            <Box mt="m">
              <StyledInput
                label={descriptionLabel}
                placeholder={descriptionPlaceholder}
                values={values[DESCRIPTION]}
                onChange={handleChange(DESCRIPTION)}
                error={errors[DESCRIPTION] && touched[DESCRIPTION] && errors[DESCRIPTION]}
                width="100%"
                color={COLOR_CONSTANTS.DENIM}
              />
            </Box>

            {(type === 'caption' || type === 'hashtag') && (
              <Box mt="m">
                <StyledInput
                  label="Include keywords"
                  placeholder="Keywords"
                  values={values[KEYWORDS]}
                  onChange={handleChange(KEYWORDS)}
                  error={errors[KEYWORDS] && touched[KEYWORDS] && errors[KEYWORDS]}
                  width="100%"
                  color={COLOR_CONSTANTS.DENIM}
                />
              </Box>
            )}

            <Flex mt="m" justifyContent="flex-end">
              <StyledButtonWithLoading isLoading={isSubmitting} onClick={handleSubmit} type="submit">
                <Text>{isSubmitting ? buttonLabelInProgress : buttonLabel}</Text>
              </StyledButtonWithLoading>
            </Flex>
          </Box>
        )}
      </Formik>

      {caption && (
        <StyledCaptionWrapper mt="xl" flexDirection="column" width="100%" position="relative">
          <StyledRunningTextWrapper>
            <Flex position="relative" overflow="auto">
              <Box position="absolute" width="100%">
                <RunningText
                  text={caption.text}
                  onFinish={() => {
                    caption.isNew = false
                    setCaption({ ...caption })
                  }}
                  isNew={caption.isNew}
                />
              </Box>
              <Box zIndex="-1" bg={COLOR_CONSTANTS.WHITE}>
                <Text>{caption.text}</Text>
              </Box>
            </Flex>
            <Flex width="100%" justifyContent="flex-end">
              <StyledCopyWrapper onClick={handleClickCopyCaption}>
                <LocalImage src={imageCopy} width="24px" height="24px" />
              </StyledCopyWrapper>
            </Flex>
          </StyledRunningTextWrapper>

          <Text fontSize="s" mt="m" pt="s" color="secondaryText">
            <Text fontSize="s" as="span" fontWeight="bold" color={COLOR_CONSTANTS.DENIM}>
              Please note:
            </Text>{' '}
            This tool may display inaccurate or offensive material that doesn’t represent Vista Social's views. You’re
            solely responsible for use of any content generated using this tool, including its compliance with
            applicable laws and third party rights.
          </Text>
        </StyledCaptionWrapper>
      )}

      <ReCAPTCHA
        sitekey="6Ld6gM0gAAAAAPwkW5CO806wVq_C1BanZ12XeZyI"
        size="invisible"
        ref={recaptchaRef}
        onExpired={onRecaptchaExpired}
      />
    </StyledFormComponentWrapper>
  )
}

FormComponent.defaultProps = {
  network: null,
  type: 'caption',
}

FormComponent.propTypes = {
  network: PropTypes.string,
  type: PropTypes.string,
}

export default FormComponent
